import React, { useState } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from "styled-components"

import emailLogo from '../img/social/email.svg'
import facebookLogo from '../img/social/facebook.svg'
import patreonLogo from '../img/social/patreon.svg'
import twitchLogo from '../img/social/twitch.svg'
import twitterLogo from '../img/social/twitter.svg'
import youtubeLogo from '../img/social/youtube.svg'

const Dropdown = styled.div`
  position: relative;

  @media(min-width: 1024px) {
    display: flex;
  }
`

const HomeLink = styled(Link)`
  font-weight: bold;
`

const NavSocialIcon = styled.a`
  margin: 0 5px;

  img {
    height: 20px;
  }
`

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query {
          topMenu: allWordpressWpApiMenusMenusItems(filter: {
            slug: {eq: "top-menu"}
          }) {
            nodes {
              id
              name
              slug
              items {
                order
                title
                url
                object
                object_slug
                type
                wordpress_children {
                  title
                  url
                  object
                  type
                }
              }
            }
          }
        }
      `}
      render={data => (
        <nav className="navbar is-transparent is-dark is-fixed-top p-t-sm p-b-sm">
          <div className="container">
            <div className="navbar-brand">
              <HomeLink to="/" className="navbar-item">
                Haus of Steve
              </HomeLink>
              <button onClick={() => setMobileOpen(!mobileOpen)} type="button" className="navbar-burger burger has-background-dark" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>
            <div className={`navbar-menu ${mobileOpen ? 'is-active' : ''}`}>
              <div className="navbar-start">
                {data.topMenu.nodes[0].items.map(item => {
                  if (item.object === 'category') {
                    const category = item.url.split('/')[item.url.split('/').length - 2];
                    return (
                      <Link
                        className="navbar-item"
                        to={`/categories/${category}`}
                        key={category}
                      >
                        {item.title}
                      </Link>
                    )
                  }
                  if (item.wordpress_children) {
                    return (
                      <Dropdown className="dropdown navbar-item has-dropdown is-hoverable" key={`${item.object_slug}-dropdown`}>
                        <Link
                          className="navbar-link"
                          to={`/${item.object_slug}`}
                          key={item.object_slug}
                        >
                          {item.title}
                        </Link>
                        <div className="navbar-dropdown is-boxed">
                          {item.wordpress_children.map(child => {
                            if (child.object === 'category') {
                              const childCategory = child.url.split('/')[child.url.split('/').length - 2];
                              return (
                                <Link
                                  className="navbar-item"
                                  to={`/categories/${childCategory}`}
                                  key={childCategory}
                                >
                                  {child.title}
                                </Link>
                              )
                            }
                            const childCategory = child.url.split('/')[child.url.split('/').length - 2];
                            return (
                              <Link
                                className="navbar-item"
                                to={`/${childCategory}`}
                                key={childCategory}
                              >
                                {child.title}
                              </Link>
                            );
                          })}
                        </div>
                      </Dropdown>
                    )
                  }
                  return (
                    <Link
                      className="navbar-item"
                      to={`/${item.object_slug}`}
                      key={item.object_slug}
                    >
                      {item.title}
                    </Link>
                  )}
                )}
              </div>
              <div className="navbar-end">
                <NavSocialIcon className="level-item" href="https://www.youtube.com/channel/UCnKM62yIuliR1zNvzkWh5Mg">
                  <img src={youtubeLogo} alt="youtube" />
                </NavSocialIcon>
                <NavSocialIcon className="level-item" href="http://facebook.com/stereotypesteve">
                  <img src={facebookLogo} alt="facebook" />
                </NavSocialIcon>
                <NavSocialIcon className="level-item" href="https://twitter.com/Stereotyp_Steve">
                  <img src={twitterLogo} alt="twitter" />
                </NavSocialIcon>
                <NavSocialIcon className="level-item" href="http://patreon.com/stereotypesteve">
                  <img src={patreonLogo} alt="patreon" />
                </NavSocialIcon>
                <NavSocialIcon className="level-item" href="https://www.twitch.tv/karban01">
                  <img src={twitchLogo} alt="twitch" />
                </NavSocialIcon>
                <NavSocialIcon className="level-item" href="mailto:tjcleland@gmail.com">
                  <img src={emailLogo} alt="email" />
                </NavSocialIcon>
              </div>
            </div>
          </div>
        </nav>
      )}
    />
  )
}

export default Navbar
