import React from 'react'
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import emailLogo from '../img/social/email.svg'
import facebookLogo from '../img/social/facebook.svg'
import patreonLogo from '../img/social/patreon.svg'
import twitchLogo from '../img/social/twitch.svg'
import twitterLogo from '../img/social/twitter.svg'
import youtubeLogo from '../img/social/youtube.svg'

const StyledFooter = styled.div`
  color: #fff;
  padding: 60px 0 0;
  min-height: 430px;
`

const Subfooter = styled.div`
  margin-top: 20px;
  padding: 30px 50px;
  background-color: #242629;
  text-align: right;

  a {
    vertical-align: middle;
    margin: 0 10px;

    img {
      height: 20px;
    }
  }
`

const FooterColumn = styled.div`
  @media(min-width: 769px) {
    width: 33%;
  }
`;

const Footer = ({data}) => {
  const pages = data.allWordpressPage.edges;
  const pagesSorted = pages.sort((c, n) => {
    return c.node.menu_order > n.node.menu_order ? 1 : -1;
  });

  return (
    <StyledFooter className="has-background-dark">
      <div className="section">
        <div className="container">
          <div className="columns">
            {
              pagesSorted.map(page => (
                <FooterColumn className="column has-text-centered" dangerouslySetInnerHTML={{ __html: page.node.content }} />
              ))
            }
          </div>
        </div>
      </div>
      <Subfooter>
        <a href="https://www.youtube.com/channel/UCnKM62yIuliR1zNvzkWh5Mg">
          <img src={youtubeLogo} alt="youtube" />
        </a>
        <a href="http://facebook.com/stereotypesteve">
          <img src={facebookLogo} alt="facebook" />
        </a>
        <a href="https://twitter.com/Stereotyp_Steve">
          <img src={twitterLogo} alt="twitter" />
        </a>
        <a href="http://patreon.com/stereotypesteve">
          <img src={patreonLogo} alt="patreon" />
        </a>
        <a href="https://www.twitch.tv/karban01">
          <img src={twitchLogo} alt="twitch" />
        </a>
        <a href="mailto:tjcleland@gmail.com">
          <img src={emailLogo} alt="email" />
        </a>
      </Subfooter>
    </StyledFooter>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: {parent_element: {slug: {eq: "_footer"}}}) {
          edges {
            node {
              id
              title
              content
              menu_order
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
)
