import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Navbar from './Navbar';
import '../scss/all.scss';
import Footer from './Footer';

const TemplateWrapper = ({data, children}) => {
  const { title: siteTitle } = data.site.siteMetadata
  return (
    <div>
      <Helmet title={`Home | ${siteTitle}`} />
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <TemplateWrapper data={data} {...props} />}
  />
)
